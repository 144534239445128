body{
  font-size: large;
}
header {
  background-color: #04B2D9;
  color: white;
  margin-bottom: 2%;
}

.nav-title {
  font-family: BebasKai;
  font-weight: bold;
  src: url('./assets/fonts/bebas-kai/BebasKai.otf');
  font-size: 3.2vw;
} 


.barbar{
  background: #D9B26A;
  color: white;
  position: -webkit-sticky;
  position: sticky;

}

.alink {
  color: white;
}
.alink:hover, .custom-control-label:hover {
  cursor: pointer;
}

nav div.collapse{
  width: 100%;
}

.title-div{
  width: 100%;
}

ul#horizontal-list {
  text-align: left;
  width: 100%;
  padding-left: 4%;
}
.navbar-dark .navbar-nav .nav-link{
  color: white;
}

.envelope{
  z-index: -1;
  position: absolute;
  max-width: 100%;
  height: auto;
  max-height: 700px;
  margin-right: 20%;
}

.letter{
  background-image: url("./assets/images/Open_Letter_Background.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  display: block;
  margin: 0 auto;
  width: 80%;
  padding-bottom: 40%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  text-align: left;
}

.letter-armenian {
  background-color: #EDEDED;
  background-repeat:no-repeat;
  display: block;
  margin: 0 auto;
  width: 80%;
  padding-bottom: 20%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  text-align: left;
}


.open-letter-writing{
  font-family: Bodoni-72-Book;
  src: url('./assets/fonts/OpenType/Bodoni-72-Book.otf');
}
div.letter h3 {
  font-family: Bodoni-06-Bold-Italic;
  font-weight: bold;
  src: url('./assets/fonts/OpenType/Bodoni-06-Bold-Italic.otf');
}

div.letter h5 {
  font-family: Bodoni-72-Bold;
  src: url('./assets/fonts/OpenType/Bodoni-72-Bold.otf');
}

.ending-open-letter{
  float: right;
  padding-right: 10%;
  padding-bottom: 5%;
}

.mission-statement{
  background-image: url("./assets/images/Misson_Statement_Parallax_3.png");
  background-size:100% 100%;
  display: block;
  background-repeat:no-repeat;
  margin-top: 5%;
}

.mission-center {
  display: flex;
  justify-content: center;
}

.mission-blog{
  padding-top: 3%;
  padding-left: 3%;
  width: 50%;
  padding-bottom: 3%;
}

.mission-blog p {
  font-family: Bodoni-72-Book-Italic;
  src: url('./assets/fonts/OpenType/Bodoni-72-Book-Italic.otf');
}

.mission-blog h3{
  font-family: Bodoni-06-Bold-Italic;
  font-weight: bold;
  src: url('./assets/fonts/OpenType/Bodoni-06-Bold-Italic.otf');
}

hr{
  border-top: 1px solid black;
}

.history{
  margin-top: 5%;
  overflow: auto;
}

section.history img {
  max-width: 100%;
  height: auto;
  width: 200px;
}

.history-subtitle{
  font-family: Bodoni-72-Book-Italic;
  font-weight: bold;
  src: url('./assets/fonts/OpenType/Bodoni-72-Book-Italic.otf');
  font-size: larger;
}
.history-content{
  font-family: Bodoni-72-Book;
  src: url('./assets/fonts/OpenType/Bodoni-72-Book.otf');
  text-align: left;
}

.history h3{
  font-family: Bodoni-06-Bold-Italic;
  font-weight: bold;
  src: url('./assets/fonts/OpenType/Bodoni-06-Bold-Italic.otf');
}
#sticky-footer {
  flex-shrink: none;
}

.contact-us{
  background-image: url("./assets/images/contact_us_parrallax.png");
  background-size:100% 100%;
  display: block;
  background-repeat:no-repeat;
}

.project-updates, .photosvideos{
  margin-bottom: 5%;
}
.project-updates div.card {
  width: 18rem; margin-right: 1%;
  margin-bottom: 1%;
}

.photovideorow{
  justify-content: center;
}

.project-updates-content{
  text-align: center;
}
.contact-form{
  padding: 2%;
  font-family: Bodoni-72-Book;
  src: url('./assets/fonts/OpenType/Bodoni-72-Book.otf');
}

input[type=submit] {
  background-color: #04B2D9;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
}

.project-update-list{
  list-style-type:none;
  margin-top: 1%;
}

.project-updates h3{
  font-weight: bold;
}

.contact-us h2{
  font-family: Bodoni-72-Bold;
  font-weight: bold;
  src: url('./assets/fonts/OpenType/Bodoni-72-Bold.otf');
}

.photosvideos h3 {
  font-weight: bold;
}

.contact-response{
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.contact-response input {
  width: 100%;
  height: 30px;
}
.contact-response textarea {
  width: 100%;
  height: 160px;
}

.project-updates .btn {
  background-color: #04B2D9;
  color: white;
  border: #04B2D9;
}

.history-block{
  display: flex;
  flex-wrap: wrap;

}

/*For mobile platforms*/

/*Tablet*/
@media screen and (max-width: 1200px) {
  .letter {
    background-image: none;
    background-color: lightgray;
    padding-bottom: 10%;
    margin-top: 15%;    
  } 
  header{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 09999;
  } 
  section.open-letter img {
    display: none;
  }
  .history-content{
    padding-left: 10%;
  }
}

/*iPhone X/XS*/
@media screen and (max-width: 420px) {
  .letter {
    background-image: none;
    background-color: lightgray;
    padding-bottom: 20%;
    margin-top: 25%;    
  }
  .letter-armenian {
    background-image: none;
    background-color: lightgray;
    padding-bottom: 20%;
    margin-top: 25%;    
  }
  header{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 09999;
  }
  .nav-title {
    font-family: BebasKai;
    font-weight: bold;
    src: url('./assets/fonts/bebas-kai/BebasKai.otf');
    font-size: 5vw;
  } 

  .nav-title-armenian{
    font-size: 4.5vw;
  }

  section.open-letter img {
    display: none;
  }

  section.history img {
    display: none;
  }
}